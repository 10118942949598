import React from 'react'
import { Box, Typography, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { useTranslation } from 'react-i18next'

import { SEO } from 'components'
import { useSizes } from 'hooks'

function Home() {
  const { t } = useTranslation()
  const classes = useStyles()
  const { mdUp } = useSizes()
  const {
    banner,
    bannerBottom,
    naturalYogurtXs,
    pineappleYogurtXs,
    strawberryYogurtXs,
    benefit1,
    benefit2,
    benefit3,
    benefit4,
    benefit5,
    benefit6,
    benefit7,
    benefit8,
    benefit9
  } = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "banner.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      bannerBottom: file(relativePath: { eq: "bannerBottom.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      naturalYogurtXs: file(relativePath: { eq: "naturalYogurtXs.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      pineappleYogurtXs: file(relativePath: { eq: "pineappleYogurtXs.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      strawberryYogurtXs: file(relativePath: { eq: "strawberryYogurtXs.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      benefit1: file(relativePath: { eq: "benefits/benefit1.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      benefit2: file(relativePath: { eq: "benefits/benefit2.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      benefit3: file(relativePath: { eq: "benefits/benefit3.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      benefit4: file(relativePath: { eq: "benefits/benefit4.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      benefit5: file(relativePath: { eq: "benefits/benefit5.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      benefit6: file(relativePath: { eq: "benefits/benefit6.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      benefit7: file(relativePath: { eq: "benefits/benefit7.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      benefit8: file(relativePath: { eq: "benefits/benefit8.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      benefit9: file(relativePath: { eq: "benefits/benefit9.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  const featuredProduct = [
    {
      id: 1,
      img: naturalYogurtXs.childImageSharp.fluid,
      name: 'naturalYogurtXs'
    },
    {
      id: 2,
      img: strawberryYogurtXs.childImageSharp.fluid,
      name: 'strawberryYogurtXs'
    },
    {
      id: 3,
      img: pineappleYogurtXs.childImageSharp.fluid,
      name: 'pineappleYogurtXs'
    }
  ]

  const benefitImgs = [
    {
      id: 1,
      img: benefit1.childImageSharp.fluid,
      description: 'benefit1'
    },
    {
      id: 2,
      img: benefit2.childImageSharp.fluid,
      description: 'benefit2'
    },
    {
      id: 3,
      img: benefit3.childImageSharp.fluid,
      description: 'benefit3'
    },
    {
      id: 4,
      img: benefit4.childImageSharp.fluid,
      description: 'benefit4'
    },
    {
      id: 5,
      img: benefit5.childImageSharp.fluid,
      description: 'benefit5'
    },
    {
      id: 6,
      img: benefit6.childImageSharp.fluid,
      description: 'benefit6'
    },
    {
      id: 7,
      img: benefit7.childImageSharp.fluid,
      description: 'benefit7'
    },
    {
      id: 8,
      img: benefit8.childImageSharp.fluid,
      description: 'benefit8'
    },
    {
      id: 9,
      img: benefit9.childImageSharp.fluid,
      description: 'benefit9'
    }
  ]

  return (
    <>
      <SEO title={t('home')} />

      <Img fluid={banner.childImageSharp.fluid} alt='Banner image' />

      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        py={5}
        flexDirection='column'
        px={mdUp ? 17 : 0}
      >
        <Typography
          className={classes.featuredProductsTitle}
          variant={mdUp ? 'h3' : 'h5'}
          align='center'
        >
          {t('featuredProducts').toUpperCase()}
        </Typography>

        <Grid container justify='center' alignItems='center'>
          {featuredProduct.map(product => {
            return (
              <Grid item xs={12} md={4} key={product.id}>
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  py={2}
                  flexDirection='column'
                >
                  <Box className={classes.productImgContainer}>
                    <Img fluid={product.img} alt='Product image' />
                  </Box>
                  <Typography
                    className={classes.productName}
                    variant='h6'
                    color='primary'
                    align='center'
                  >
                    {t(product.name).toUpperCase()}
                  </Typography>
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </Box>

      <Img
        fluid={bannerBottom.childImageSharp.fluid}
        alt='Banner bottom image'
      />

      <Typography
        className={classes.ourBenefitTitle}
        variant={mdUp ? 'h3' : 'h5'}
        align='center'
      >
        {t('ourBenefits').toUpperCase()}
      </Typography>

      <Grid container justify='center' alignItems='stretch'>
        {benefitImgs.map(({ id, img, description }) => {
          return (
            <Grid item xs={12} md={4} key={id}>
              <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                py={2}
                flexDirection='column'
              >
                <Img
                  fluid={img}
                  alt='Benefit image'
                  className={classes.benefitImg}
                />

                <Typography
                  variant='h6'
                  align='center'
                  className={classes.benefitImgDescription}
                >
                  {t(description).toUpperCase()}
                </Typography>
              </Box>
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  featuredProductsTitle: {
    color: theme.palette.secondary.light,
    fontWeight: 'bold',
    marginBottom: theme.spacing(4)
  },
  productName: {
    fontWeight: 'bold'
  },
  productImgContainer: {
    width: 120,
    marginBottom: theme.spacing(3)
  },
  ourBenefitTitle: {
    color: theme.palette.secondary.light,
    fontWeight: 'bold',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4)
  },
  benefitImg: {
    width: 200,
    marginBottom: theme.spacing(3)
  },
  benefitImgDescription: {
    color: theme.palette.secondary.light
  }
}))

export default Home
